import {filter, first} from 'rxjs/operators';
import {Component, Injector} from '@angular/core';
import {IntBaseListComponent} from '@shared/base/int-base-list.component';
import {PageConfig} from '@core/models/page-config.model';
import {RmcReportService} from '@features/dashboard-rmc-reports/services/rmc-report.service';
import {ListOptions} from '@capturum/api';
import {Field} from '@shared/field/field.enum';
import {Modules} from '@core/enums/modules.enum';
import {Entities} from '@core/components/entities.enum';
import {SidebarService} from '@core/components/sidebar/services/sidebar.service';
import {NavigationStart} from '@angular/router';
import {ReportPagesEnum} from '@features/dashboard-rmc-reports/pages/reports/report-pages.enum';

@Component({
    selector: 'app-rmc-reports',
    templateUrl: './rmc-reports.component.html',
    styleUrls: ['./rmc-reports.component.scss'],
})
export class RmcReportsComponent extends IntBaseListComponent<any> {
    public reportPageName = null;
    public reportID = null;
    public stopLoading = false;

    public optionsFilter: ListOptions = {
        filters: [{field: Field.Name, value: Modules.DashboardRMC}],
    };
    private redirectStarted = false;
    private initPath = null;

    constructor(injector: Injector, private sidebarService: SidebarService, public apiService: RmcReportService) {
        super(injector);

    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.initPath = window.location.pathname;
        this.route.data.pipe(first()).subscribe((data) => {
            this.reportPageName = data?.reportPageName;
        });
        this.getReports();
        this.menuItemNavigation();
    }

    public getReports(): void {
        this.apiService.getReports(this.optionsFilter).subscribe(reportArr => {
            this.stopLoading = true;
            if (reportArr && reportArr.data && reportArr.data[0]) {
                this.reportID = reportArr.data[0]['report_id'];
            } else {
                this.toastService.error(
                    this.translateService.instant('toast.error.title'),
                    this.translateService.instant('dashboard-rmc.dashboard-reports.report-not-found.label'),
                );
            }
        }, () => {
            this.stopLoading = true;
            this.toastService.error(
                this.translateService.instant('toast.error.title'),
                this.translateService.instant('dashboard-rmc.dashboard-reports.report-not-found.label'),
            );
        });
    }

    public getDefaultConfig(): PageConfig {
        return {
            title: this.getPageTitle(),
            buttons: [],
        };
    }

    public changeReportName(reportName: string): void {
        if (this.reportPageName !== reportName) {
            this.reportPageName = reportName;
        }
    }

    private menuItemNavigation(): void {
        this.router.events.pipe(filter((routerEvent) => {
            return routerEvent instanceof NavigationStart;
        })).subscribe((event: NavigationStart) => {

            if (this.redirectStarted === false && event.url.includes(`${Modules.DashboardRMC}/${Entities.DashboardReports}`) && event.url !== `/${Modules.DashboardRMC}/${Entities.UploadedFiles}`) {
                this.redirectStarted = true;
                // prevents redirect
                this.router.navigateByUrl(this.initPath, {replaceUrl: true});

                this.sidebarService.getEvent().subscribe((clickEvent: PointerEvent) => {
                    // change url
                    window.history.pushState('', '', clickEvent.currentTarget['dataset']['test']);

                    document.querySelector('.item.active')?.classList?.remove(Entities.Active);
                    document.querySelector(`[data-test="${clickEvent.currentTarget['dataset']['test']}"]`)?.classList?.add(Entities.Active);
                    this.layoutService.setPageConfig({
                        title: this.getPageTitle(clickEvent.currentTarget['dataset']['test']),
                        buttons: [],
                    });
                    this.manageReportNames(clickEvent.currentTarget['dataset']['test']);
                });
            }

            setTimeout(() => {
                this.redirectStarted = false;
            }, 100);
        });
    }

    private manageReportNames(url: string) {
        switch (url) {
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvFactsheet}`:
                this.changeReportName(ReportPagesEnum.VsvFactSheet);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvProgress}`:
                this.changeReportName(ReportPagesEnum.VsvProgress);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvEducation}`:
                this.changeReportName(ReportPagesEnum.VsvEducation);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvWork}`:
                this.changeReportName(ReportPagesEnum.VsvWork);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvOther}`:
                this.changeReportName(ReportPagesEnum.VsvOther);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvTargets}`:
                this.changeReportName(ReportPagesEnum.VsvTargets);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvRegionalWorklists}`:
                this.changeReportName(ReportPagesEnum.RegionalWorklist);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportVsvRegionalWorklists}`:
                this.changeReportName(ReportPagesEnum.ExportRegional);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpOverview}`:
                this.changeReportName(ReportPagesEnum.JikpOverview);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpEducation}`:
                this.changeReportName(ReportPagesEnum.JikpEducation);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpWork}`:
                this.changeReportName(ReportPagesEnum.JikpWork);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpDaycare}`:
                this.changeReportName(ReportPagesEnum.JikpDaycare);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpOther}`:
                this.changeReportName(ReportPagesEnum.JikpOther);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpRegionalWorklists}`:
                this.changeReportName(ReportPagesEnum.JikpRegionalWorklist);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcNoCDelivery}`:
                this.changeReportName(ReportPagesEnum.NotCDelivery);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportJikpRegionalWorklists}`:
                this.changeReportName(ReportPagesEnum.JikpExportRegionalWorklist);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportVsv}`:
                this.changeReportName(ReportPagesEnum.VsvExport);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvUitvallers}`:
                this.changeReportName(ReportPagesEnum.VsvUitvallers);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvHistorie}`:
                this.changeReportName(ReportPagesEnum.VsvHistorie);
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvExport}`:
                this.changeReportName(ReportPagesEnum.VsvExportSimple);
                break;
        }
    }

    private getPageTitle(reportUrl: string = null): string {
        let title = '';
        switch (reportUrl ?? this.router.url) {
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvFactsheet}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-factsheet.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvProgress}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-progress.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvEducation}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-education.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvWork}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-work.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvOther}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-other.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvTargets}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-targets.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvRegionalWorklists}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-regional-worklists.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportVsvRegionalWorklists}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.export-regional-vsv-worklists.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpOverview}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-overview.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpEducation}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-education.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpWork}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-work.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpDaycare}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-daycare.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpOther}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-other.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcJikpRegionalWorklists}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.jikp-regional-worklists.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcNoCDelivery}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.no-c-delivery.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportJikpRegionalWorklists}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.export-regional-jikp-worklists.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcExportVsv}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.export-vsv.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvUitvallers}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-dropouts.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvHistorie}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-history-results.label');
                break;
            case `/${Modules.DashboardRMC}/${Entities.DashboardReports}/${Entities.DashboardRmcVsvExport}`:
                title = this.translateService.instant('dashboard-rmc.dashboard-reports.vsv-export.label');
                break;
        }
        return title;
    }

}
