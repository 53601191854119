export enum ReportPagesEnum {
  VsvFactSheet = 'VSV | Factsheet',
  VsvProgress = 'VSV | Voortgang',
  VsvEducation = 'VSV | ➜ Onderwijs',
  VsvWork = 'VSV | ➜ Arbeid',
  VsvOther = 'VSV | ➜ Anders',
  VsvTargets = 'VSV | Streefcijfers',
  RegionalWorklist = 'VSV | Regionale werklijst(en)',
  ExportRegional = 'VSV | Export Regionale VSV werklijst',
  NotCDelivery = 'NietMeerInCLevering',
  JikpOverview = 'JIKP | Overzicht',
  JikpEducation = 'JIKP | Onderwijs',
  JikpWork = 'JIKP | Arbeid',
  JikpDaycare = 'JIKP | Dagbesteding',
  JikpOther = 'JIKP | Anders',
  JikpRegionalWorklist = 'JIKP | Regionale werklijst(en)',
  JikpExportRegionalWorklist = 'JIKP | Export Regionale JIKP werklijst',
  VsvExport = 'VSV | Exportbestand',
  Leerlingniveau = 'Leerlingniveau',
  Schoolniveau = 'Schoolniveau',
  SchoolniveauRegional = 'Schoolniveau - zonder regionaal',
  ReportMbo = 'MBO',
  ReportMboRegional = 'MBO - zonder regionaal',
  Diploma = 'Diploma behaald',
  DiplomaRegional = 'Diploma behaald - zonder regionaal',
  VoOverzicht = 'VO Overzicht',
  MboOverzicht = 'MBO Overzicht',
  MboLevering = 'MBO-levering uitleg',
  TotalDataOverview = 'Totaaloverzicht gegevens',
  VsvUitvallers = 'VSV | Uitvallers',
  VsvHistorie  = 'VSV | Historie',
  VsvExportSimple = 'VSV | Export',
}
